import React, { useContext, useEffect, useRef, useState } from 'react'
import Input from '../../../../../../components/input/input'
import DefaultStatusModals from '../../../../../../components/modal/default-status-modals'
import { WanOpMode } from '../../bifrost/wan/wan-constants'
import { DeviceContext } from '../../manage-page'
import Button from '../../../../../../components/button/button'
import { backendStatus } from '../../../../../../backend/backend'


import './customize.css'
import DeviceFactory from '../../../../../apis/device-factory'

export default function CustomizeManagementPage() {


    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)
    const [saving, setSaving] = useState(false)
    const [file, setFile] = useState('')
    const device = useContext(DeviceContext)

    const mounted = useRef(true)


    if(DeviceFactory.isTR069(device.model)){
        WanOpMode['BRIDGE'] = 3 //Temp: sharing lan page with tr069. Needs a refact.
    }

    useEffect(() => {

        return () => {
            mounted.current = false
        }

        // eslint-disable-next-line
    }, [])



    const saveCustomize = async() => {


        var reader = new FileReader();

        if (device.data.model === "SR1041E"){

            reader.readAsArrayBuffer(file);

            reader.onload = async() => {
                let base64File = btoa(String.fromCharCode(...new Uint8Array(reader.result)));
                let payload = {
                    fileData: base64File,
                    fileName: file.name
                };
                let result = await device.updateResource('customize', JSON.stringify(payload))
                result = await device.apply()
                if(result.status !== backendStatus.SUCCESS){
                    setSaving(false)
                    setError(true)
                    return
                }
            }

        }else{
            reader.readAsText(file);
            reader.onload = async() => {
            let result = await device.updateResource('customize', reader.result)
            result = await device.apply()
            if(result.status !== backendStatus.SUCCESS){
                setSaving(false)
                setError(true)
                return
            }
        }
    }

        // let result = await device.uploadResource('customize', 
        //     file
        // )

//            let result = await device.updateResource('customize', reader.result)

}

const dismissModal = () => {
    setSuccess(false)
    setSaving(false)
    setError(false)
}

return <div className='customize-management-page'>

<DefaultStatusModals
success={success}
error={error}
saving={saving}
continueFn={dismissModal}
></DefaultStatusModals>


<div className='customize-flex-container'>
<div className='customize-ip'>

<form className='upload-fw-form' onSubmit={saveCustomize}>

<div className='subtitle'>Enviar um novo arquivo config</div>



<Input id='fwmodelo'
className='itb-input'
type='file'
label='Modelo'
onChange={(e) => setFile(e.target.files[0])}

>
</Input>

<Button text='Salvar'></Button>

</form>

</div>

</div>

</div>
}
